@charset "UTF-8";

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@use '@angular/material' as mat;
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import "variable";


.nivus {
    @import "colors/nivus";
    @include mat.all-component-themes($nivus-app-theme);
}


/*@import "icons/themify-icons/themify-icons.css";
@import "icons/font-awesome/css/font-awesome.css";*/
@import "icons/flag-icon-css/flag-icon.min.css";
@import "fonts";
@import "spinner";
@import "app";
@import "header";
@import "widgets";

@import "pages";
@import "sidebar";
@import "dark-sidebar";
@import "responsive";
@import "rtl";
// Dark Theme
.dark {
    .mat-drawer-container {
        color: #8999a0 !important;
    }
    @import "colors/dark";
    @include mat.all-component-themes($dark-theme);
}

$custom-typography: mat.define-typography-config(
    $font-family: "Poppins",
);
@include mat.core($custom-typography);
