@use '@angular/material' as mat;
@import '@angular/material/theming';

$nivus-palette-blue: (
    50 : #e0f2fb,
    100 : #b3e0f4,
    200 : #80cbed,
    300 : #4db6e6,
    400 : #26a6e0,
    500 : #0096db,
    600 : #008ed7,
    700 : #0083d2,
    800 : #0079cd,
    900 : #0068c4,
    A100 : #ecf5ff,
    A200 : #b9daff,
    A400 : #86bfff,
    A700 : #6db1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$nivus-palette-orange: (
    50 : #feeae2,
    100 : #fdccb6,
    200 : #fbaa85,
    300 : #f98754,
    400 : #f86e30,
    500 : #f7540b,
    600 : #f64d0a,
    700 : #f54308,
    800 : #f33a06,
    900 : #f12903,
    A100 : #ffffff,
    A200 : #ffe8e5,
    A400 : #ffbab2,
    A700 : #ffa498,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Define the default theme (same as the example above).
$nivus-app-primary: mat.define-palette($nivus-palette-blue, 500);
$nivus-app-accent: mat.define-palette($nivus-palette-orange, 500, 200, 900, 200);
$nivus-app-theme: mat.define-light-theme(
        $nivus-app-primary,
        $nivus-app-accent
);

$nivus-app-warn: mat.define-palette(mat.$pink-palette);
// Create the theme object (a Sass map containing all of the palettes).
$nivus-app-theme: mat.define-light-theme($nivus-app-primary, $nivus-app-accent, $nivus-app-warn);
$themecolor: #0096db;
$themecolor-alt: #f7540b;
$white: #fff;

