﻿/* poppins - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/styles/fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/styles/fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/styles/fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/styles/fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/assets/styles/fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/styles/fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/styles/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/styles/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/styles/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/styles/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/styles/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/styles/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/styles/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/styles/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/styles/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/styles/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/assets/styles/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/styles/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Material Icons';
    font-weight: 400;
    font-style: normal;
    src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('/assets/styles/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 24px; /* Preferred icon size */
    font-weight: normal;
    line-height: 1;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
.force-UTF-8{
    content: "¡";
}

